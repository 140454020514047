import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import * as styles from './LightBox.module.css';

export default class LightBox extends React.Component {
  static propTypes = {
    img: PropTypes.string,
    unmountMe: PropTypes.func.isRequired,
  };
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }
  componentDidMount() {
    document.documentElement.appendChild(this.el);
  }

  componentWillUnmount() {
    document.documentElement.removeChild(this.el);
  }

  render() {
    // gatsby-plugin-react-next needed
    if (!this.props.img) {
      return null;
    }
    return ReactDOM.createPortal(
      <div className={styles.LightBox}>
        <div className={styles.background} />
        <div className={styles.close} onClick={this.props.unmountMe}>
          {getCloseIcon()}
        </div>
        <div className={styles.imageContainer}>
          <img src={this.props.img} alt={this.props.img} />
        </div>
      </div>,
      this.el,
    );
  }
}
function getCloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path
        fill="#fff"
        d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
      />
    </svg>
  );
}
