import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import * as styles from './ButtonLink.module.css';

export function ButtonLink(props) {
  const addClassName = props.className ? ` ${props.className}` : '';

  if (!props.href) {
    return null;
  }
  return props.href.indexOf('//') > -1 ? (
    <a className={`${styles.ButtonLink} Button ${addClassName}`} href={props.href} target={props.target} aria-label={props.title}>
      {props.children}
    </a>
  ) : (
    <Link className={`${styles.ButtonLink} Button ${addClassName}`} to={props.href} aria-label={props.title}>
      {props.children}
    </Link>
  );
}

ButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
};
ButtonLink.defaultProps = {
  target: '',
};
