import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './SimpleHeading.module.css';

SimpleHeading.propTypes = { title: PropTypes.string.isRequired };
SimpleHeading.defaultProps = {};

export function SimpleHeading(props) {
  return (
    <section className={styles.SimpleHeading}>
      <h2>{props.title}</h2>
      <span className={styles.border} />
    </section>
  );
}
