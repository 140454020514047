import React from 'react';
import PropTypes from 'prop-types';

import LightBox from './LightBox';

class LightBoxLink extends React.Component {
  static propTypes = {
    img: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.props = props;
    this.blocked = false; //block overlapping clicks
    this.state = { opened: false };
  }
  closeLightBox = () => {
    this.blocked = true;
    this.setState({ opened: false });
  };
  openLightBox = () => {
    if (this.blocked) {
      this.blocked = false;
    } else {
      this.setState({ opened: true });
    }
  };
  render() {
    return (
      <span onClick={this.openLightBox} style={{ cursor: 'pointer' }}>
        {this.state.opened ? <LightBox img={this.props.img} unmountMe={this.closeLightBox} /> : this.props.children}
      </span>
    );
  }
}

export default LightBoxLink;
