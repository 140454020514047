import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { DefaultLayout } from '../layouts/Default';
import fecha from 'fecha';

import * as styles from './news.module.css';
import { ButtonLink } from '../components/ButtonLink';
import { SimpleHeading } from '../components/SimpleHeading';
import { Helmet } from 'react-helmet';
import LightBoxLink from '../components/LightBox/LightBoxLink';

export default function NewsPageTemplate({ data }) {
  const { item } = data;
  const { title, teaser, text, date, category, images, preview } = item;
  let image = images[0] ? images[0] : preview.previewImage;
  image = getImage(image) ? getImage(image) : getImage(preview.previewImage);
  /* image = image.fullsize && image.fullsize.src ? image.fullsize.src : image.preview.src; */
  let backToListLabel = 'alle News anzeigen';
  let backToListLink = '/aktuelles';

  return (
    <DefaultLayout>
      <section className={clsx(styles.news, 'defaultWidth')}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={teaser} />
        </Helmet>
        <div className={styles.imagesContainer}>
          {image && (
            <LightBoxLink img={image}>
              <GatsbyImage image={image} alt={''} />
            </LightBoxLink>
          )}
        </div>
        <div className={styles.textContainer}>
          <SimpleHeading title={title} />
          <h4 className={styles.date}>{fecha.format(new Date(date), 'D.M.YYYY')}</h4>
          <p dangerouslySetInnerHTML={{ __html: text }} />
          <div className={styles.backButtonContainer}>
            <ButtonLink href={backToListLink}>{backToListLabel}</ButtonLink>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
}

export const query = graphql`
  query ($id: String) {
    item: cockpitNews(id: { eq: $id }) {
      id
      title
      teaser
      text
      date
      images {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  }
`;
